import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="cerebremancer"></a><h3>CEREBREMANCER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" width="100%">
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80452.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d4.
    <a id="cerebremancer-requirements"></a><h6>Requirements</h6>
To qualify to become a cerebremancer, a character must fulfill all the
following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(arcana) 6 ranks, <a href="psionicSkills.html#knowledge-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge (psionics)</a> 6 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> Able to cast
2nd-level arcane spells.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psionics:</span> Able to
manifest 2nd-level powers.</p>
    <h6>Class Skills</h6>
The cerebremancer&#8217;s class skills (and the key ability for each skill)
are <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(Int), <a href="skillsAll.html#decipher-script" style={{
      "color": "rgb(87, 158, 182)"
    }}>Decipher Script</a> (Int), <a href="skillsAll.html#knowledge" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge</a>
(arcana) Int, <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a> (Int), <a href="skillsAll.html#profession" style={{
      "color": "rgb(87, 158, 182)"
    }}>Profession</a>
(Wis), <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a>
(Int), and <a href="skillsAll.html#spellcraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Spellcraft</a> (Int).
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier.
    </p>
    <a id="table-the-cerebremancer"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Cerebremancer</span>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "50%"
          }}>Spells per Day/Powers Known
          </th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing manifesting class
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+5</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">+1 level of existing arcane
spellcasting class/+1 level of existing manifesting class
          </td>
        </tr>
      </tbody>
    </table>
    <a id="cerebremancer-class-features"></a><h6>Class Features</h6>
All the following are class features of the cerebremancer
prestige class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Cerebremancers gain no proficiency with any weapon or armor.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells per Day/Powers Known:</span>
When a new cerebremancer level is attained, the character gains new
spells per day as if he had also attained a level in any one arcane
spellcasting class he belonged to before he added the prestige class.
He gains additional power points per day and access to new powers as if
he had also gained a level in any one manifesting class he belonged to
previously. He does not, however, gain any other benefit a character of
either class would have gained (bonus metamagic, metapsionic, or item
creation feats, psicrystal special abilities, and so on). This
essentially means that he adds the level of cerebremancer to the level
of whatever other arcane spellcasting class and manifesting class the
character has, then determines spells per day, caster level, power
points per day, powers known, and manifester level accordingly.
If a character had more than one arcane spellcasting class or more than
one manifesting class before he became a cerebremancer, he must decide
to which class he adds each level of cerebremancer for purpose of
determining spells per day, caster level, power points per day, powers
known, and manifester level.</p>
    <p>
    </p>
    <p>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      